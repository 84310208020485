import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import {ghcolors} from 'react-syntax-highlighter/dist/esm/styles/prism';

import Layout from 'components/Layout'
import SEO from 'components/Seo'
import GridTemplate from 'components/GridTemplate'

const capitalize = str => `${str.charAt(0).toUpperCase()}${str.slice(1)}`
const formatName = name => capitalize(name.split('_').join(' '))

const labJsonQuery = graphql`
  query {
    allLabDataJson {
      edges {
        node {
          category
          data {
            body
            name
          }
        }
      }
    }
  }
`

const LabPage = () => {
  const edges = useStaticQuery(labJsonQuery)
  const datas = edges.allLabDataJson.edges.map(c => ({ ...c, newData: c.node.data.map(i => {
    let [, , ...code] = i.body.split('\n');
    code.pop();

    return ({
      name: i.name,
      body: code.join('\n').replace('~~~' , '')
    })
  }) }))


  console.log('dataa: ', {datas})

  // const [activeCategory, setActiveCategory] = React.useState(0)
  // const activeList = React.useMemo(() => data[activeCategory], [
  //   activeCategory,
  // ])

  // console.log('data: ', { data, categories })

  // React.useEffect(() => {
  //   console.log('active categories: ', { activeCategory, activeList })
  // }, [activeCategory])

  return (
    <Layout>
      <SEO title='Lab' />
      <GridTemplate>
        <div className='categories'>
        
        </div>

        {datas.map((data, key) => (
          <div key={key}>
            <strong className='title'>{data.node.category}</strong>
          
            <ul>
              {Array.isArray(data.newData) &&
                data.newData.length > 0 &&
                data.newData.map((item, index) => {
                  
                  return (
                  <li key={index}>
                    <strong>{item.name}</strong>
                    <SyntaxHighlighter style={ghcolors} language="javascript">{item.body}</SyntaxHighlighter>
                  </li>
                )})}
            </ul>
            

          </div>
        ))}


        
        
      </GridTemplate>
    </Layout>
  )
}



export default LabPage
